import Vue from 'vue';
import VueRouter from 'vue-router';


import Index from '../views/Index';
import License from '../views/License';
import FAQ from '../views/FAQ';

import auth from "./auth";
import promo from './promo';
import profile from "./profile";
import wallet from "./wallet/wallet";
import NotFound from "../views/NotFound";
import Game from "../views/Game";
import WaitSms from "@/components/Profile/WaitSms.vue";

const meta = require('../json/meta.json');
const domain = window.location.hostname;

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '',
            name: 'index',
            component: Index,
            meta: {title: meta[domain].title, description: meta[domain].description}
        },
        {
            path: '/play/:id',
            name: 'play',
            component: Game,
            meta: {requiresAuth: true},
            props: ({params}) => ({...params, demo: false})
        },
        {
            path: '/wait',
            name: 'wait',
            component: WaitSms,
            props: ({params}) => ({...params, demo: false})
        },
        {
            path: '/demo/:id',
            name: 'demo',
            component: Game,
            meta: {requiresAuth: false},
            props: ({params}) => ({...params, demo: true})
        },
        ...auth,
        ...promo,
        ...profile,
        ...wallet,
        {
            path: '/license',
            name: 'license',
            component: License,
        },
        {
            path: '/faq',
            name: 'faq',
            component: FAQ,
        },
        {path: '*', component: NotFound}
    ]
})
